<template lang="pug">
	section.reviews
		h2.reviews__title(id="reviewsTitle")
			span.text-white {{ $t(`landingPage.reviews.title[0]`) }}
			span {{ $t(`landingPage.reviews.title[1]`) }}
			span.text-white {{ $t(`landingPage.reviews.title[2]`) }}
		span.d-block.reviews__subtitle {{ $t(`landingPage.reviews.subtitle`) }}

		flickity.reviews__flickity(:options="flickityOptionts" ref="flickityRef")
			.reviews__card(v-for="(item, i) in 8" :key="`review-${i}`")
				.reviews__rating
					svg.reviews__rating-icon(v-for="(item, i) in 5" width='27' height='26' viewbox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg')
						path(d='M11.6733 1.3403C12.378 -0.240675 14.622 -0.240671 15.3267 1.3403L17.5886 6.41465C17.8793 7.06672 18.4955 7.51441 19.2054 7.58934L24.7304 8.17247C26.4518 8.35415 27.1452 10.4883 25.8594 11.6471L21.7324 15.3664C21.202 15.8443 20.9667 16.5687 21.1148 17.2671L22.2675 22.7018C22.6266 24.3951 20.8112 25.7141 19.3118 24.8493L14.4992 22.0735C13.8808 21.7168 13.1192 21.7168 12.5008 22.0735L7.68821 24.8493C6.18881 25.7141 4.37335 24.3951 4.73249 22.7018L5.88521 17.267C6.03334 16.5687 5.79798 15.8443 5.26764 15.3664L1.14061 11.6471C-0.145213 10.4883 0.548234 8.35415 2.2696 8.17247L7.79456 7.58934C8.50452 7.51441 9.12071 7.06672 9.41137 6.41465L11.6733 1.3403Z' fill='#FCB64D')
				p.text-white.reviews__text(v-html="$t(`landingPage.reviews.list[${i}].text`)")
				.reviews__author
					img.reviews__author-avatar(:src="`/assets/img/landingPage/Plain/reviews/avatar-${i}.webp`" :alt="$t(`landingPage.reviews.list[${i}].title`)" width="67" height="67")
					div
						span.text-white.reviews__author-name(v-html="$t(`landingPage.reviews.list[${i}].title`)")
						span.reviews__author-activity(v-html="$t(`landingPage.reviews.list[${i}].subtitle`)")

</template>

<script>
import Flickity from 'vue-flickity';

export default {
    name: 'Reviews',
    components: {
        Flickity,
    },
    data: () => ({
        flickityOptionts: {
            initialIndex: 0,
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
        },
    }),
    mounted() {
        setTimeout(() => {
            this.setCardHeight();
        }, 0);
    },
    methods: {
        setCardHeight() {
            const flickityElement = this.$refs.flickityRef.$el;
            const flickityViewportElement = flickityElement.querySelector('.flickity-viewport');
            const cards = flickityElement.querySelectorAll('.reviews__card');
            let maxHeight = 0;

            cards.forEach(card => {
                const height = card.offsetHeight;

                maxHeight = Math.max(maxHeight, height);
            });

            flickityViewportElement.style.height = `${maxHeight}px`;
        },
    },
};
</script>

<style lang="scss" scoped>
.reviews {
    background-color: var(--lc-dark-grey);
    padding: 120px 0;

    @media (max-width: 767.98px) {
        padding: 95px 0;
    }

    @media (max-width: 575.98px) {
        padding: 70px 0;
    }

    &__title {
        color: var(--lc-transparent-2-white);
        max-width: 915px;
        margin: 0 auto !important;
        text-align: center;
        padding: 0 16px;

        @media (max-width: 767.98px) {
            padding: 0 12.5px;
        }
    }

    &__subtitle {
        color: var(--lc-white-smoke);
        margin: 45px 0 44px;
        text-align: center;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        padding: 0 16px;

        @media (max-width: 767.98px) {
            padding: 0 12.5px;
            margin-top: 46.5px;
            margin-bottom: 44.5px;
        }
    }

    .flickity-viewport {
        height: 100% !important;
    }

    &__card {
        width: 70.2%;
        margin-right: 20px;
        padding: 42px 47.5px 39px;
        border-top: 1px solid var(--lc-transparent-3-white);
        border-bottom: 1px solid var(--lc-transparent-3-white);
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 767.98px) {
            width: 77.8%;
            padding: 32px 24px 29px;
        }

        @media (max-width: 575.98px) {
            width: 85.3%;
            padding: 22px 0 19px;
        }
    }

    &__rating {
        display: flex;
        justify-content: center;
        margin-bottom: 58px;

        @media (max-width: 767.98px) {
            margin-bottom: 48px;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 37px;
        }

        &-icon {
            margin: 0 8px;
        }
    }

    &__text {
        font-size: 22px;
        line-height: 30px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 47.5px;

        @media (max-width: 767.98px) {
            font-size: 19px;
            line-height: 28px;
            margin-bottom: 39.5px;
        }

        @media (max-width: 575.98px) {
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 31.5px;
        }

        &:before {
            content: '“';
        }

        &:after {
            content: '”';
        }

        &:after,
        &:before {
            color: var(--lc-goldenrod);
            font-size: 32px;
            line-height: 20px;

            @media (max-width: 767.98px) {
                font-size: 24px;
            }

            @media (max-width: 575.98px) {
                font-size: 16px;
            }
        }
    }

    &__author {
        margin-top: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 575.98px) {
            justify-content: flex-start;
        }

        &-avatar {
            margin-right: 20.5px;

            @media (max-width: 767.98px) {
                width: 60px;
                height: 60px;
            }

            @media (max-width: 575.98px) {
                width: 52px;
                height: 52px;
            }
        }

        &-name {
            font-size: 22px;
            font-weight: 600;
            line-height: 22px;

            @media (max-width: 767.98px) {
                font-size: 19px;
                line-height: 19px;
            }

            @media (max-width: 575.98px) {
                font-size: 16px;
                line-height: 16px;
            }
        }

        &-activity {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: var(--lc-transparent-2-white);
            margin-top: 10px;
            max-width: 358px;

            @media (max-width: 575.98px) {
                font-size: 13px;
                line-height: 13px;
            }
        }
    }
}
</style>

<template lang="pug">
	section.authors(:class="!islocaleRuSystemAndIp ? 'is-en' : ''")
		.d-flex.justify-content-center.flex-wrap.authors__statistics.animation
			.authors__statistics-item
				span.authors__statistics-number {{ animatedNumber1 }}
				span.authors__statistics-txt {{ $t(`landingPage.authors.statistics[0]`) }}
			.authors__statistics-item
				span.authors__statistics-number {{ animatedNumber2 }}
				span.authors__statistics-txt {{ $t(`landingPage.authors.statistics[1]`) }}
			.authors__statistics-item
				span.authors__statistics-number {{ animatedNumber3 }}
				span.authors__statistics-txt {{ $t(`landingPage.authors.statistics[2]`) }}
			.authors__statistics-item
				span.authors__statistics-number {{ animatedNumber4 }}
				span.authors__statistics-txt {{ $t(`landingPage.authors.statistics[3]`) }}

		//-.authors__cases(id="authorsCases")
			h2.authors__title
				span.d-block.color-grey {{ $t(`landingPage.authors.title[0]`) }}
				span.d-block {{ $t(`landingPage.authors.title[1]`) }}
			flickity.authors__cases-flickity(:options="flickityOptionsCases" ref="casesFlickity")
				.authors__card(v-for="(item, i) in 6" :key="`author-${i}`" :style="{ paddingLeft: i == 0 ? marginLeftContainer : '0', paddingRight: i == 5 ? marginLeftContainer : '0' }")
					.authors__item.is-cases
						.authors__cases-inner
							img.authors__cases-video(:src="`/assets/img/landingPage/Plain/authors/cases/case-${i+1}.webp`" @click="openVideo(i+1)")
							.authors__cases-play(@click="openVideo(i+1)")
								svg(width='47' height='46' viewbox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg')
									path(d='M8.9502 16.8635C8.9502 8.8373 14.7097 5.55056 21.7572 9.56365L27.4431 12.7959L33.129 16.0282C40.1765 20.0413 40.1765 26.6148 33.129 30.6278L27.4431 33.8601L21.7572 37.0924C14.7097 41.1055 8.9502 37.8187 8.9502 29.7925V23.328V16.8635Z' fill='white' stroke='white' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round')
									path(opacity='0.01' fill-rule='evenodd' clip-rule='evenodd' d='M45.752 1.53906V45.1201H1.58984V1.53906H45.752Z' stroke='#007AA3' stroke-width='1.3')
							img.authors__cases-avatar(:src="`/assets/img/landingPage/Plain/authors/avatars/avatar-${i+1}.webp`" :alt="$t(`landingPage.authors.list[${i}].name`)" width="74" height="74")
						span.authors__cases-name(v-html="$t(`landingPage.authors.list[${i}].name`)")
						h3.authors__cases-resource(v-html="$t(`landingPage.authors.list[${i}].nameResource`)")
						a.authors__link(:href="'https://paywall.pw/' + $t(`landingPage.authors.list[${i}].href`)" target="_blank") {{ $t(`landingPage.authors.list[${i}].href`) }}

		.authors__projects(v-if="islocaleRuSystemAndIp" id="authorsMainProjects")
			h2.authors__title
				span.d-block
					| {{ $t(`landingPage.projects.title[0]`) }}
					| {{ animatedNumber5 }}
					| {{ $t(`landingPage.projects.title[1]`) }}
				span.d-block.color-grey {{ $t(`landingPage.projects.title[2]`) }}
			
			.swiper-container.swiper-transition(:style="{ marginLeft: marginLeftContainer }")
				.swiper-wrapper
					.swiper-slide.authors__card.is-project(v-for="(item, i) in sortedBestProjects" :key="`project-${i}`")
						a.d-block.authors__item.is-projects(:href="'https://paywall.pw/' + item.url.split('/')[item.url.split('/').length - 1]" target="_blank")
							img.authors__projects-picture(:src="'https://api.paywall.pw' + item.image" :alt="item.title" width="600" height="337")
							.d-flex.flex-column.justify-content-between.align-items-start.authors__projects-inner
								.w-100
									h3.authors__projects-name {{ item.title }}
									p.authors__projects-txt {{ item.description }}
								a.authors__link.is-projects(:href="'https://paywall.pw/' + item.url.split('/')[item.url.split('/').length - 1]" target="_blank") {{ 'paywall.pw/' + item.url.split('/')[item.url.split('/').length - 1] }}

		.video-modal.is-vertical(v-if='isShowVideo' @click="closeVideo()" ref="modalAuthorsVideo")
			video(autoplay loop controls playsinline)
				source(v-if="!isMobile" :src="`/assets/video/landingPage/Plain/cases/case-${openSomeVideo}.webm`" type='video/webm')
				source(:src="isMobile ? `/assets/video/landingPage/Plain/cases/isMobile/case-${openSomeVideo}.mp4` : `/assets/video/landingPage/Plain/cases/case-${openSomeVideo}.mp4`" type='video/mp4')

			.close-modal
				.close-modal__icon
</template>

<script>
import Flickity from 'vue-flickity';
import axios from 'axios';
import { APIURL } from '@/api/index.js';

import Swiper from 'swiper';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

export default {
    name: 'Authors',
    components: {
        Flickity,
        Swiper,
    },
    props: {
        marginLeftContainer: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        merchants: '',
        bestProjects: [],
        totalContentProjects: null,
        totalSubscriptions: null,
        oldContentProjects: null,
        flickityOptionsCases: {
            initialIndex: 0,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: true,
            contain: true,
            autoPlay: 3000,
        },
        flickityOptionsProjects: {
            initialIndex: 0,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: true,
            contain: true,
            adaptiveHeight: false,
            wrapAround: true,
            autoPlay: 3000,
            pauseAutoPlayOnHover: false,
            selectedAttraction: 0.0003,
            friction: 1,
        },
        animatedNumber1: 3000,
        animatedNumber2: 5000,
        animatedNumber3: 70000,
        animatedNumber4: 1000,
        animatedNumber5: 3000,
        isShowVideo: false,
        lastScrollPosition: 0,
        openSomeVideo: null,
        showProjects: false,
        swiper: null,
    }),
    mounted() {
        setTimeout(() => {
            this.getMerchants();
            this.getLandingData();

            if (this.$refs.casesFlickity) {
                const casesFlickity = this.$refs.casesFlickity.$flickity;
                casesFlickity.resize();
                this.setCardsHeight();
            }
        }, 0);
        window.addEventListener('scroll', this.startAnimation);

        this.swiper = new Swiper('.swiper-container', {
            modules: [Autoplay, FreeMode],
            slidesPerView: 'auto',
            speed: 7500,
            loop: true,
            freeMode: true,
            on: {
                init: function () {
                    this.autoplay.start();
                    this.params.autoplay.delay = 0;
                    this.params.autoplay.disableOnInteraction = false;
                    this.params.autoplay.pauseOnMouseEnter = false;
                    this.update();
                },
                touchStart: function () {
                    if (this.params.freeMode == false) {
                        this.params.freeMode = true;
                        this.update();
                    }
                },
                touchEnd: function () {
                    this.autoplay.start();
                },
                click: function () {
                    this.params.freeMode = false;
                    this.update();
                },
            },
        });
    },
    beforeMount() {
        window.removeEventListener('scroll', this.startAnimation);
    },
    computed: {
        sortedBestProjects: function () {
            return this.bestProjects.sort((a, b) => {
                const order = [
                    1207661314, 1578685496, 1139730766, 1830247751, 1961200207, 2068290124, 1736104692, 1944886866,
                    1400825745, 1849227632, 1533411183, 1502369483, 1157919619, 1736104692, 1944393934, 1290528515,
                    1192935701, 1905443137, 1886247062, 1770566421, 1454296540, 1903268835, 1982515147, 1443024226,
                    1528752133,
                ];
                return order.indexOf(a.id) - order.indexOf(b.id);
            });
        },
    },
    methods: {
        async getMerchants() {
            try {
                const responce = await axios.get(`${APIURL}public/merchants_with_payments`);
                this.merchants = responce.data;
            } catch (error) {
                this.merchants = '100500';
            }
        },
        async getLandingData() {
            try {
                const responce = await axios.get(`${APIURL}public/landing.json`);
                this.bestProjects = responce.data.projects.PLAIN.ACTIVE.favorites;
                this.showProjects = true;

                this.totalContentProjects = responce.data.projects.PLAIN.ACTIVE.total;
                this.totalSubscriptions = responce.data.subscriptions.PLAIN.ACTIVE.total;
                this.oldContentProjects = responce.data.projects.PLAIN.ACTIVE.old;

                this.$nextTick(() => {
                    this.setEqualHeightProjectsInnerElems();
                });
            } catch (error) {
                console.log(error);
            }
        },
        startAnimation() {
            const currentScrollPosition = window.scrollY;

            if (document.querySelector('.authors__statistics')) {
                const authors = document.querySelector('.authors__statistics');
                const rectAuthors = authors.getBoundingClientRect();
                const isVisibleAuthors = rectAuthors.top < window.innerHeight - 30;

                if (isVisibleAuthors && currentScrollPosition >= this.lastScrollPosition) {
                    const timer = setInterval(() => {
                        if (this.animatedNumber1 < this.merchants) {
                            this.animatedNumber1 += 1;
                        }
                        if (this.animatedNumber4 < this.oldContentProjects) {
                            this.animatedNumber4 += 1;
                        }
                        if (
                            this.animatedNumber1 == this.merchants &&
                            this.animatedNumber2 == 1000 &&
                            this.animatedNumber3 == 1000 &&
                            this.animatedNumber4 == 100
                        )
                            clearInterval(timer);
                    }, 30);

                    const timer2 = setInterval(() => {
                        if (this.animatedNumber2 < this.totalContentProjects) {
                            this.animatedNumber2 += 1;
                        }
                        if (this.animatedNumber3 < this.totalSubscriptions) {
                            this.animatedNumber3 += 1;
                        }
                        if (
                            this.animatedNumber1 == this.merchants &&
                            this.animatedNumber2 == 1000 &&
                            this.animatedNumber3 == 1000 &&
                            this.animatedNumber4 == 100
                        )
                            clearInterval(timer2);
                    }, 15);
                }

                const projects = document.querySelector('.authors__projects');

                if (projects) {
                    const rectProjects = projects.getBoundingClientRect();
                    const isVisibleProjects = rectProjects.top < window.innerHeight - 30;

                    if (isVisibleProjects && currentScrollPosition >= this.lastScrollPosition) {
                        const timerProjects = setInterval(() => {
                            if (this.animatedNumber5 < this.merchants) {
                                this.animatedNumber5 += 1;
                            }
                            if (this.animatedNumber5 == this.merchants) clearInterval(timerProjects);
                        }, 10);
                    }
                }

                this.lastScrollPosition = currentScrollPosition;
            }
        },
        openVideo(cardNumber) {
            this.isShowVideo = true;
            document.body.classList.add('modal-open');

            this.openSomeVideo = cardNumber;
        },
        closeVideo() {
            if (event.target.tagName != 'VIDEO') {
                this.isShowVideo = false;
                document.body.classList.remove('modal-open');
            }
        },
        setCardsHeight() {
            const casesFlickity = this.$refs.casesFlickity.$flickity;
            const casesResourceElems = document.querySelectorAll('.authors__cases-resource');
            let maxHeight = 0;

            casesResourceElems.forEach(el => {
                const height = el.offsetHeight;
                maxHeight = Math.max(maxHeight, height);
            });

            casesResourceElems.forEach(el => {
                el.style.height = `${maxHeight}px`;
            });

            casesFlickity.resize();
        },
        setEqualHeightProjectsInnerElems() {
            let projectsInnerElems = document.querySelectorAll('.authors__projects-inner');

            let maxHeight = 0;

            for (let i = 0; i < projectsInnerElems.length; i++) {
                projectsInnerElems[i].style.height = 'auto';
            }

            for (let i = 0; i < projectsInnerElems.length; i++) {
                maxHeight = Math.max(maxHeight, projectsInnerElems[i].offsetHeight);
            }

            for (let i = 0; i < projectsInnerElems.length; i++) {
                projectsInnerElems[i].style.height = `${maxHeight}px`;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.authors {
    padding-top: 283px;

    @media (max-width: 767.98px) {
        padding-top: 187.5px;
    }

    @media (max-width: 575.98px) {
        padding-top: 92px;
    }

    &.is-en {
        padding-top: 90px;

        @media (max-width: 767.98px) {
            padding-top: 80px;
        }

        @media (max-width: 575.98px) {
            padding-top: 70px;
        }
    }

    &__statistics {
        margin-bottom: 104px;

        @media (max-width: 1150px) {
            max-width: 800px;
            margin: 0 auto 104px;
        }

        @media (max-width: 767.98px) {
            margin-bottom: 79px;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 54px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 200px;
            min-height: 122.5px;
            width: 267px;
            background-color: var(--lc-light);
            color: var(--lc-dodger-blue);
            margin: 0 10px 16px;

            @media (max-width: 1150px) {
                margin-left: 8px;
                margin-right: 8px;
                width: calc(50% - 16px);
            }

            @media (max-width: 767.98px) {
                min-height: 113px;
            }

            @media (max-width: 575.98px) {
                width: calc(100% - 16px);
                min-height: 104px;
            }
        }

        &-number {
            font-weight: 700;
            font-size: 40px;
            line-height: 64px;
            letter-spacing: -0.64px;
        }

        &-txt {
            letter-spacing: -0.64px;
            line-height: 26px;
            margin-top: 2.5px;

            @media (max-width: 767.98px) {
                margin-top: -4px;
            }

            @media (max-width: 575.98px) {
                margin-top: -10px;
            }
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 38px !important;
        padding: 0 16px;

        @media (max-width: 767.98px) {
            margin-bottom: 25px !important;
            padding: 0 12.5px;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 12px !important;
        }
    }

    &__card {
        margin-right: 32px;
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 100%;

        @media (max-width: 767.98px) {
            margin-right: 24px;
        }

        @media (max-width: 575.98px) {
            margin-right: 16px;
        }

        &.is-project {
            display: flex;
            flex-direction: column;
            animation: fillHeight 0s 0.2s linear forwards;
            width: 600px;

            @media (max-width: 767.98px) {
                width: 444px;
            }

            @media (max-width: 575.98px) {
                width: 287.5px;
            }
        }
    }

    &__item {
        width: 270px;
        border-radius: 20px;
        transition: transform var(--la-time);

        @media (max-width: 767.98px) {
            width: 236.5px;
        }

        @media (max-width: 575.98px) {
            width: 203px;
        }

        &:has(.authors__cases-play:hover) {
            transform: scale(1.02);
        }

        &.is-projects {
            width: 100%;
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
            color: var(--lc-ebony) !important;
            transition: transform var(--la-time);
            height: 100%;

            &:hover {
                transform: scale(1.02);

                @media (max-width: 767.98px) {
                    transform: none;
                }
            }
        }

        &.is-cases {
            padding-bottom: 15px;
            border: 1px solid var(--lc-light-grayish-blue);
        }
    }

    &__link {
        margin-left: 15px;
        font-size: 14px;
        line-height: 25px;
        position: relative;

        &.is-projects {
            margin-left: 0;

            &:after {
                top: 2px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            right: -26px;
            top: -4px;
            width: 22px;
            height: 21px;
            background: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.2984 10.5379L13.0936 5.25684L7.8125 7.46171' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.78619 15.7412L13.0508 5.36328' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
                no-repeat center;
            transition: background-image var(--la-time);
        }

        &:hover {
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.2984 10.5379L13.0936 5.25684L7.8125 7.46171' stroke='%230071F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.78619 15.7412L13.0508 5.36328' stroke='%230071F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__cases {
        &-inner {
            position: relative;
            height: 478.6px;

            @media (max-width: 767.98px) {
                height: 419px;
            }

            @media (max-width: 575.98px) {
                height: 360px;
            }
        }

        &-video {
            width: calc(100% + 1px);
            margin-top: -0.5px;
            margin-left: -0.5px;
            height: 100%;
            border-radius: 20px;
            object-fit: cover;
        }

        &-avatar {
            position: absolute;
            bottom: -32px;
            left: 15px;
            border: 2px solid var(--lc-white);
            border-radius: 12px;

            @media (max-width: 767.98px) {
                height: 64px;
                width: 64px;
                left: 14px;
                bottom: -27px;
            }

            @media (max-width: 575.98px) {
                height: 55px;
                width: 55px;
                left: 12px;
                bottom: -23px;
            }
        }

        &-name {
            display: block;
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            color: var(--lc-grey-2);
            margin-left: 15px;
            margin-top: 39px;
            margin-bottom: 11px;

            @media (max-width: 767.98px) {
                margin-top: 35px;
            }

            @media (max-width: 575.98px) {
                margin-top: 31px;
            }
        }

        &-resource {
            font-weight: 700 !important;
            margin-bottom: 6px !important;
            margin-left: 15px !important;
            font-size: 16px !important;
            line-height: 16px !important;
        }

        &-play {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            cursor: pointer;
            background-color: var(--lc-transparent-2-white);
            border-radius: 50%;
            width: 76px;
            height: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__projects {
        margin-bottom: 62px;

        @media (max-width: 767.98px) {
            margin-bottom: 56px;
        }

        @media (max-width: 575.98px) {
            margin-bottom: 50px;
        }

        &-picture {
            width: 100%;
            height: 337.5px;
            border-radius: 20px 20px 0 0;
            object-fit: cover;

            @media (max-width: 767.98px) {
                border-radius: 14px 14px 0 0;
                height: 249.5px;
            }

            @media (max-width: 575.98px) {
                border-radius: 8px 8px 0 0;
                height: 161.5px;
            }
        }

        &-inner {
            padding: 21px 18.5px 19px;
            border: 1px solid var(--lc-light-grayish-blue);
            border-top: 0;
            border-radius: 0 0 20px 20px;
            height: calc(100% - 337.5px);

            @media (max-width: 767.98px) {
                border-radius: 0 0 14px 14px;
                padding-top: 16px;
                padding-bottom: 14px;
                height: calc(100% - 249.5px);
            }

            @media (max-width: 575.98px) {
                border-radius: 0 0 8px 8px;
                padding-top: 11px;
                padding-bottom: 9.5px;
                height: calc(100% - 161.5px);
            }
        }

        &-name {
            font-size: 24px !important;
            font-weight: 700 !important;
            line-height: 28px !important;

            @media (max-width: 767.98px) {
                font-size: 21px !important;
            }

            @media (max-width: 575.98px) {
                font-weight: 600 !important;
                font-size: 18px !important;
            }
        }

        &-txt {
            line-height: 22px;
            font-weight: 400;
            margin-top: 10.5px;
            margin-bottom: 7.5px;
            -webkit-line-clamp: 4;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @media (max-width: 767.98px) {
                font-size: 15px;
            }

            @media (max-width: 575.98px) {
                font-size: 14px;
                margin-top: 9px;
                margin-bottom: 10px;
            }
        }
    }

    @keyframes fillHeight {
        0% {
            height: auto;
        }
        100% {
            height: 100%;
        }
    }

    .swiper-transition {
        .swiper-wrapper {
            transition-timing-function: linear;
        }
    }

    .swiper-container {
        width: 100%;
        height: auto;
    }
}
</style>
